import React, { Component } from "react";
import MPSBarLoader from "../../../components/MPSBarLoader";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";
import SurveyGroupView from "./SurveyGroupView";
import ModalPopup from "../../../components/ModalPopup";
import history from "../../../history";
import {
  getSurveyDataReadyForSubmission,
  setDataInGroup,
  validateQuestion,
  getReferenceRanges,
} from "./Utils";
import Messages from "../../Messages";
import Text from "../../../components/Text";
import libraryService from "../../../services/contentLibraryService";
import ArticlePreview from "../../ContentLibrary/Preview/ArticlePreview";
import { injectIntl } from "react-intl";

class SurveyRendererOnePager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: this.props.survey,
      currentGroup: 0,
      invalidAnswer: false,
      invalidField: "",
      refRangeMin: null,
      refRangeMax: null,
      showLib: false,
      library: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ survey: nextProps.survey });
  }
  setRanges = (rMin, rMax) => {
    this.setState({ refRangeMin: rMin, refRangeMax: rMax });
  };
  setData = (gIndex, qIndex, value) => {
    this.setState({
      survey: {
        ...this.state.survey,
        items: setDataInGroup(this.state.survey.items, gIndex, qIndex, value),
      },
    });
  };

  startSubmitting = () => {
    this.setState({ error: false });

    let valid = this.validateQuestions();
    if (valid) {
      if (this.props.embedded) {
        // this.toggleValidAnswers();
        this.submitAnswers();
      } else {
        this.setState({ submitConfirmation: true });
      }
    } else {
      this.setState({ invalidAnswer: true });
    }
  };

  closeSubmitting = () => {
    this.setState({ submitConfirmation: false });
  };

  submitAnswers = () => {
    let { survey } = this.state;
    const noResponseString = this.props.intl.formatMessage(Messages.noResponse);
    getSurveyDataReadyForSubmission(survey, noResponseString);
    this.props
      .submitAnswers({
        referencedSurveyId: survey.id,
        items: survey.items,
      })
      .then((response) => {
        this.closeSubmitting();
      });
  };

  goToPrevGroup = () => {
    this.setState({ currentGroup: this.state.currentGroup - 1 });
  };

  validateQuestions = () => {
    this.setState({ error: null, pefError: null, fevError: null });
    let { survey } = this.state;
    let groups = survey.items;
    let valid = true;
    let invalidQ = "";
    for (let i = 0; i < groups.length; i++) {
      let group = groups[i];
      let questions = [...group.items];
      for (let j = 0; j < questions.length; j++) {
        let question = questions[j];
        //FOR CIAN: use question.referenceR
        valid = valid && validateQuestion(survey, group, question, i, this);
        console.log("Question " + question.text + " " + valid);
        if (!valid) {
          const [refMin, refMax] = getReferenceRanges(survey, group, question);
          if (
            (question.type === "INTEGER" || question.type === "DECIMAL") &&
            refMin !== Number.MAX_VALUE
          ) {
            this.setRanges(refMin, refMax);
          } else {
            this.setRanges(null, null);
          }
          if (Object.hasOwn(question, "answers")) {
            this.setState({ invalidAnswer: true });
          } else {
            invalidQ = question.text;
          }
          if (group.items.length === 1) {
            this.setState({ invalidField: group.text });
          } else {
            this.setState({ invalidField: question.text });
          }
          return false;
        }
      }
    }
    this.setState({ invalidAnswer: false });
    this.setState({ invalidField: invalidQ });
    return valid;
  };

  cancel = () => {
    history.goBack();
  };

  getPageWidth = (embedded, popUp) => {
    if (embedded) {
      return "col-md-12 col-sm-12";
    }
    if (popUp) {
      return "col-md-10 offset-md-1 col-sm-12";
    }
    return "col-md-8 offset-md-2 col-sm-12";
  };

  toggleValidAnswers = () => {
    this.setState({ invalidAnswer: !this.state.invalidAnswer });
  };

  showLib = (libId) => {
    this.setState({ showLib: true});
    libraryService.loadArticle(libId).then((article) => {
      this.setState({ library: article});
    });
  };

  hideLib = () => {
    this.setState({showLib : false, library: false})
  }
  render() {
    let { loading, submitting, embedded, popUp } = this.props;
    let { survey, submitConfirmation, error, pefError, fevError, showLib, library } = this.state;
    let associatedLibrary = survey?.associatedLibrary;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!survey) {
      return null;
    }

    let groups = survey.items;

    return (
      <div className="added-margin-bottom-table">
        {!embedded && !popUp && (
          <MPSButton type="link" onClick={this.cancel} className="text-right">
            <Text content={Messages.cancel} />
          </MPSButton>
        )}
        <div className="row justify-content-center">
          {embedded ? (
            <div
              style={{
                fontWeight: 600,
                fontSize: "0.9rem",
              }}
              className="text-center my-2 mb-2"
            >
              {survey.title}
            </div>
          ) : (
            <h5 className="text-center mb-2">{survey.title}</h5>
          )}
          {associatedLibrary && (
            <MPSButton
              onClick={() => this.showLib(survey.associatedLibrary)}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
                marginLeft: "10px",
                backgroundColor: "black",
                color: "white",
                border: "none",
              }}
            >
              ?
            </MPSButton>
          )}
        </div>
        <div className="row">
          <div className={this.getPageWidth(embedded, popUp)}>
            <p
              style={{
                color: this.state.invalidAnswer ? "red" : "white",
                opacity: this.state.invalidAnswer ? "100%" : "0%",
              }}
            >
              <Text content={Messages.valid_answer} />
              {this.state.invalidField}{" "}
              {this.state.refRangeMin !== null &&
                `(${this.state.refRangeMin} - 
                  ${this.state.refRangeMax})`}
            </p>
            {groups.map((group, index) => {
              return (
                <div key={group.id} className="survey-group-card">
                  <SurveyGroupView
                    group={group}
                    gIndex={index}
                    setData={this.setData}
                    embedded={embedded}
                    validateQuestions={this.validateQuestions}
                    noGroup
                  />
                </div>
              );
            })}
            {!this.props.previewMode && (
              <>
                <div className="text-center my-1">
                  {error && (
                    <div style={{ color: "red" }}>
                      <Text content={Messages.answer_mandatory_qs} />
                    </div>
                  )}

                  {pefError && (
                    <div style={{ color: "red" }}>
                      <Text content={Messages.value_bw_100and800} />
                    </div>
                  )}

                  {fevError && (
                    <div style={{ color: "red" }}>
                      <Text content={Messages.value_bw_1and6} />
                    </div>
                  )}
                </div>
                <p
                  style={{
                    color: this.state.invalidAnswer ? "red" : "white",
                    opacity: this.state.invalidAnswer ? "100%" : "0%",
                  }}
                >
                  <Text content={Messages.valid_answer} />{" "}
                  {this.state.invalidField}
                </p>
                <div className="d-flex justify-content-end align-items-center my-2">
                  <div>
                    {!submitting && (
                      <MPSButton
                        color="primary"
                        size={embedded ? "md" : "lg"}
                        className={`text-capitalize ${
                          embedded ? "" : "font-weight-bold"
                        }`}
                        onClick={this.startSubmitting}
                      >
                        <Text content={Messages.submit_assessment} />
                      </MPSButton>
                    )}
                    {submitting && (
                      <div className="d-flex" style={{ width: 50 }}>
                        <MPSBarLoader />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {submitConfirmation && !this.props.previewMode && (
          <ModalPopup
            title={<Text content={Messages.submit_survey_q} />}
            onClose={this.closeSubmitting}
          >
            <p>
              <Text content={Messages.sure_submit_survey} />
            </p>

            <div className="d-flex justify-content-end align-items-center">
              <a onClick={this.closeSubmitting} className="text-cancel">
                <Text content={Messages.cancel} />
              </a>

              <div>
                {!submitting && (
                  <MPSButton
                    color="primary"
                    className="text-capitalize font-weight-bold"
                    onClick={this.submitAnswers}
                  >
                    <Text content={Messages.submit} />
                  </MPSButton>
                )}

                {submitting && (
                  <div className="d-flex" style={{ width: 50 }}>
                    <MPSBarLoader />
                  </div>
                )}
              </div>
            </div>
          </ModalPopup>
        )}
        <div>
          {showLib && !this.props.previewMode && (
            <ModalPopup onClose={this.hideLib}>
              <ArticlePreview article={library} embed />
            </ModalPopup>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(SurveyRendererOnePager);
