import React, { Component } from "react";
import { connect } from "react-redux";
import patientsService from "../../../services/patientsService";
import SurveyRenderer from "./SurveyRenderer";
import surveysService from "../../../services/surveysService";
import { showLocalizedToaster } from "../../../State/actions/toaster";
import Messages from "../../Messages";
import SurveyRendererOnePager from "./SurveyRendererOnePager";
import { isPatientOrMember } from "../../../security/roles";
import PatientAppServices from "../../../services/PatientAppServices";

class SurveyRendererContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  componentDidMount() {
    let artifactId =
      this.props.artifactId || this.props.match.params.artifactId;
    let patientId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.patientId
        ? this.props.match.params.patientId
        : this.props.patientId;

    this.setState({ loading: true });
    let p = null;
    if (this.props.anonymous) {
      let { mrn, appointmentRefNumber } = this.props;
      p = PatientAppServices.getAppointmentScreening(
        appointmentRefNumber,
        mrn,
        artifactId
      );
    } else if(this.props.external){
      p = patientsService.loadArtifact(
        `/api/a/survey/?p=${patientId}&s=${artifactId}`
      )
    } 
    else {
      p = patientsService.loadArtifact(
        `/api/surveys/${artifactId}?patientId=${patientId}`
      );
    }

    p.then(survey => {
      this.setState({ survey, loading: false });
    }).catch(e => {
      this.setState({ loading: false });
    });
  }

  submitAnswers = responseData => {
    let statusId = null;
    let patientId =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.patientId
        ? this.props.match.params.patientId
        : this.props.patientId;

    if (this.props.match) {
      statusId = this.props.match.params.statusId;
    }
    this.setState({ submitting: true });
    if (this.props.anonymous) {
      let { mrn, appointmentRefNumber } = this.props;
      return PatientAppServices.doAppointmentScreening(
        appointmentRefNumber,
        mrn,
        responseData
      ).then(response => {
        this.props.onSubmission(response);
      });
    } 
    else if(this.props.external){
      return surveysService
      .submitExternalAnswers(responseData, patientId)
      .then(response => {
        this.setState({ submitting: false });
        this.props.showToaster(
          { content: Messages.survey_submitted },
          "success"
        );
        if (this.props.onComplete) {
          this.props.onComplete();
        } else {
          this.props.history.goBack();
        }
      })
      .catch(e => {
        this.setState({ submitting: false });
      });
    }
    else {
      return surveysService
        .submitAnswers(statusId, responseData, patientId)
        .then(response => {
          this.setState({ submitting: false });
          this.props.showToaster(
            { content: Messages.survey_submitted },
            "success"
          );
          if (this.props.onComplete) {
            this.props.onComplete();
          } else {
            this.props.history.goBack();
          }
        })
        .catch(e => {
          this.setState({ submitting: false });
        });
    }
  };

  render() {
    let { survey, loading, submitting } = this.state;

    if (survey && survey.showInOnePageForWeb) {
      return (
        <>
        {this.props?.external && survey?.patientName && 
        <h5 style={{marginLeft: 20}}>
          {survey?.patientName.text}  
        </h5>}
        <SurveyRendererOnePager
          survey={survey}
          loading={loading}
          submitAnswers={this.submitAnswers}
          submitting={submitting}
          embedded={this.props.embedded}
          popUp={this.props.popUp}
        />
        </>
      );
    }
    return (
      <>
      {this.props?.external && survey?.patientName && 
        <h5 style={{marginLeft: 20}}>
          {survey?.patientName.text}  
        </h5>}
      
      <SurveyRenderer
        survey={survey}
        loading={loading}
        submitAnswers={this.submitAnswers}
        submitting={submitting}
        embedded={this.props.embedded}
        popUp={this.props.popUp}
      />
      </>
    );
  }
}

let data = (state, props) => {
  return {
    userRole: state.user.role,
    patientId: isPatientOrMember(state.user.role)
      ? state.user.data.patientId
      : props.patientId
  };
};

let actions = {
  showToaster: showLocalizedToaster
};

export default connect(
  data,
  actions
)(SurveyRendererContainer);
