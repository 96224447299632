import { http } from "../utils/http";
import { API_ENDPOINTS } from "../config";
import { store } from "../State/store/configureStore";
import { showToaster } from "../State/actions/toaster";
import { ROLE_PATIENT } from "../security/roles";

class ContentLibraryService {
  static load(page = 0, filter) {
    let {
      searchTerm,
      pTypeId,
      topicId,
      status,
      allStatus,
      onlyFeaturedContent,
      sortBy = "lastUpdatedOn",
      sortDir = "DESC",
      onlyPublicContent
    } = filter;

    let url = API_ENDPOINTS.loadContentLibrary() + `?page=${page}&size=20`;

    if (searchTerm) {
      url += "&searchTerm=" + searchTerm;
    }
    if (pTypeId) {
      url += "&pTypeId=" + pTypeId;
    }
    if (topicId) {
      url += "&topicId=" + topicId;
    }
    if (status) {
      url += "&status=" + status;
    }

    if (allStatus) {
      url += "&allStatus=True";
    }
    if (onlyFeaturedContent) {
      url += "&onlyFeaturedContent=true";
    }
    if (onlyPublicContent) {
      url += "&onlyPublicContent=true";
    }
    if (sortBy) {
      url += "&sortBy=" + sortBy;
    }
    if (sortDir) {
      url += "&sortDir=" + sortDir;
    }

    return http.request(url).then(data => {
      let list = data && data._embedded ? data._embedded.libraries : [];
      return {
        number: data.page.number,
        totalPages: data.page.totalPages,
        loadMore: page + 1 < data.page.totalPages,
        totalItems: data && data.page ? data.page.totalElements : 0,
        list: list
      };
    });
  }

  static add(data) {
    return http
      .request(API_ENDPOINTS.addLibraryContent(), "post", data)
      .then(data => {
        store.dispatch(showToaster("Library has been added.", "success", 5));
        return data;
      });
  }

  static loadTopics(patientTypeId) {
    let url = API_ENDPOINTS.loadTopics() + `?page=0&size=1000`;
    if (patientTypeId) {
      url += "&pTypeId=" + patientTypeId;
    }

    return http.request(url).then(data => {
      return data && data._embedded ? data._embedded.libraryTopics : [];
    });
  }

  static loadArticle(articleId) {
    let role = store.getState().user.role;
    let url = null;
    if (role === ROLE_PATIENT) {
      url = API_ENDPOINTS.loadArticlePatient(articleId);
    } else {
      url = API_ENDPOINTS.loadArticle(articleId);
    }
    return http.request(url);
  }

  static edit(contentId, data) {
    return http
      .request(API_ENDPOINTS.loadArticle(contentId), "patch", data)
      .then(result => {
        store.dispatch(showToaster("Library has been updated.", "success", 5));
      });
  }

  static addTopic(data) {
    return http.request(API_ENDPOINTS.addLibraryTopic(), "post", data);
  }

  static loadTopic(topicId) {
    return http.request(API_ENDPOINTS.loadTopic(topicId)).then(data => {
      return data._embedded ? data._embedded.libraryTopics : [];
    });
  }

  static editTopic(topicId, data) {
    return http.request(API_ENDPOINTS.loadTopic(topicId), "patch", data);
  }

  static deleteTopic(topicId) {
    return http.request(API_ENDPOINTS.loadTopic(topicId), "delete");
  }

  static deleteArticle(articleId) {
    return http.request(API_ENDPOINTS.loadArticle(articleId), "delete");
  }

  static loadLibraryItemForSignup(libId, locale) {
    return http.request(API_ENDPOINTS.loadLibForSignUp(libId, locale));
  }

  static exportLibraryItem(libId) {
    return http.request(API_ENDPOINTS.libraryExport(libId));
  }

  static cloneALibrary(libId, data) {
    return http.request(API_ENDPOINTS.cloneLibrary(libId), "post", data);
  }

  static loadLibraryByName(name) {
    return http.request(API_ENDPOINTS.loadContentLibraryByName(name))
    .then(response => {
      return response;
    });
  }
}

const libraryService = ContentLibraryService;

export default libraryService;
