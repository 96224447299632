import React, { Component } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import MPSButton from "../../../../components/Buttons/Buttons/MPSButton";
import MPSSelect from "../../../../components/MPSSelect";

class StringObservation extends Component {
  static getDisplayValue(attributeValue, valuePlace, multi) {
    if (
      multi &&
      attributeValue.valueObservation &&
      attributeValue.valueObservation.values
    ) {
      return attributeValue.valueObservation.values
        .map((v) =>  v.valueString || v.valueCode?.display)
        .join(", ");
    }

    if (
      !multi &&
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      if (valuePlace === "from") {
        return attributeValue.valueObservation.value.valueFromString;
      } else if (valuePlace === "to") {
        return attributeValue.valueObservation.value.valueToString;
      }
      return attributeValue.valueObservation.value.valueString;
    }
  }

  static getOptionsList(options) {
    return options.map((option) => {
      let val = option.valueObservation.value.valueString;
      return { value: val, label: val };
    });
  }

  setValue = (option) => {
    let { attributeValue, multi, setData } = this.props;
    if (multi) {
      if (option) {
        attributeValue.valueObservation.values = option.map((o) => ({
          valueString: o.value,
          display: o.label
        }));
      } else {
        attributeValue.valueObservation.values = [];
      }
    } else {
      if (option) {
        attributeValue.valueObservation.value.valueString = option.value;
      } else {
        attributeValue.valueObservation.value = {};
      }
    }
    setData(attributeValue);
  };

  getValue = () => {
    let { attributeValue, multi } = this.props;
    if (multi) {
      let values = attributeValue.valueObservation.values || [];
      return values.map((v) => v.valueString || v.valueCode?.code);
    }
    return attributeValue.valueObservation.value.valueString;
  };

  setTextValue = (index, value) => {
    let { attributeValue, setData } = this.props;
    let values = attributeValue.valueObservation.values || [];
    values[index] = { valueString: value };
    attributeValue.valueObservation.values = [...values];
    setData(attributeValue);
  };

  addTextValue = () => {
    let { attributeValue, setData } = this.props;
    let values = attributeValue.valueObservation.values || [];
    values.push({ valueString: "" });
    attributeValue.valueObservation.values = [...values];
    setData(attributeValue);
    this.props.onBlur();
  };

  removeTextValue = (index) => {
    let { attributeValue, setData } = this.props;
    let values = attributeValue.valueObservation.values || [];
    values = [...values];
    values.splice(index, 1);
    attributeValue.valueObservation.values = values;
    setData(attributeValue);
    this.props.onBlur();
  };

  //KLC MAY 09 2024 added creatable - for GP NAME only (it is hoped)
  render() {
    let {
      attributeValue,
      choiceList,
      options = [],
      setData,
      observationCode,
      multi,
      creatable,
      isMultiLine,
      noOfLinesInMultiline,
      noOfWordsInMultiline
    } = this.props;
    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueString;
    attributeValue.valueObservation.code = observationCode;
    if (choiceList) {
      return (
        <div className="inputHolder flex-row">
          <MPSSelect
            className="ui-attribute-el-select"
            name="form-field-name"
            value={this.getValue()}
            onChange={this.setValue}
            options={options.map((option) => {
              let val = option.valueObservation.value.valueString || option.valueCode?.code;
              let display = option.valueObservation.value.valueString || option.valueCode?.display;
              return { value: val, label: display };
            })}
            multi={multi}
            creatable={creatable}
            onBlur={this.props.onBlur}
            style={{
              minWidth: "150px",
              with: "auto",
              color: "#3e515b",
            }}
          />
        </div>
      );
    } else {
      if (multi) {
        let values = attributeValue.valueObservation.values || [];
        return (
          <div className="flex-column">
            {values.map((v, index) => (
              <div className="inputHolder mb-2 flex-row align-content-center">
                <Input
                  className="ui-attribute-el"
                  value={v.valueString}
                  onChange={(e) => {
                    this.setTextValue(index, e.target.value);
                  }}
                  type="text"
                  onBlur={this.props.onBlur}
                />
                <MPSButton
                  type="link"
                  onClick={() => {
                    this.removeTextValue(index);
                  }}
                  style={{ fontWeight: 500, marginLeft: "10px" }}
                >
                  -
                </MPSButton>
              </div>
            ))}
            <div className="inputHolder">
              <MPSButton
                type="link"
                onClick={this.addTextValue}
                style={{ fontWeight: 500 }}
              >
                +
              </MPSButton>
            </div>
          </div>
        );
      }
      return (
        <div className="inputHolder flex-row">
          {isMultiLine ? (
            <div>
              {this.state?.errorMessage  && (
                <p style={{color:"red"}}> {this.state.errorMessage}</p>
              )}
            <textarea
              className="ui-attribute-multiline"
              value={obsVal}
              onChange={(e) => {
                const { value } = e.target;
                const lines = value.split('\n');
                const words = value.split(/\s+/).filter(word => word.length > 0);
                if (words.length > noOfWordsInMultiline) {
                  this.setState({
                    text: lines.slice(0, noOfWordsInMultiline).join('\n'),
                    errorMessage: `You cannot enter more than ${noOfWordsInMultiline} words.`
                  });
                } 
                else if(lines.length > noOfLinesInMultiline){
                  this.setState({
                    text: lines.slice(0, noOfWordsInMultiline).join('\n'),
                    errorMessage: `You cannot enter more than ${noOfLinesInMultiline} lines.`
                  });
                }
                else {
                  attributeValue.valueObservation.value.valueString = e.target.value;
                  setData(attributeValue, e.target.value);
                  this.setState({errorMessage: null});
                  }
                }
              }
              onBlur={this.props.onBlur}
              rows={this.props.noOfLinesInMultiline}
            />
            </div>
          ) : (
            <Input
              className="ui-attribute-el"
              value={obsVal}
              onChange={(e) => {
                attributeValue.valueObservation.value.valueString =
                  e.target.value;
                setData(attributeValue, e.target.value);
              }}
              type={"text"}
              onBlur={this.props.onBlur}
            />
          )}
        </div>
      );
    }
  }
}

export default StringObservation;
