import ContactPoint from "./Elements/ContactPoint";
import AddressAttribute from "./Elements/AddressAttribute";
import PositionAttribute from "./Elements/PositionAttribute";
import StringObservation from "./Elements/StringObservation";
import CCCObservation from "./Elements/CCCObservation";
import HumanNameAttribute from "./Elements/HumanNameAttribute";
import CodeableConceptCode from "./Elements/CodeableConceptCode";
import PlaceholderAttribute from "./Elements/PlaceholderAttribute";
import StringAttribute from "./Elements/StringAttribute";
import IntegerAttribute from "./Elements/IntegerAttribute";
import DateObservation from "./Elements/DateObservation";
import IntegerObservation from "./Elements/IntegerObservation";
import moment from "moment";
import { birthDateFormatter, validateEmail } from "../../../utils/utils";
import { validatePhoneNumber } from "../../../utils/phone";
import DoubleObservation from "./Elements/DoubleObservation";
import {
  ROLE_DOCTOR,
  ROLE_GENERAL_STAFF,
  ROLE_NURSE,
} from "../../../security/roles";
import FeaturesService from "../../../services/featuresService";

let OBSERVATION_TYPES = [
  "OBSERVATION",
  "OBSERVATION_BOOLEAN",
  "OBSERVATION_DATE",
  "OBSERVATION_INTEGER",
  "OBSERVATION_DOUBLE",
  "OBSERVATION_QUANTITY",
  "OBSERVATION_CODEABLE_CONCEPT_CODE",
];

let CHOICE_LIST_TYPES = [
  "STRING",
  "INTEGER",
  "DATE",
  "CODEABLE_CONCEPT_CODE",
  "OBSERVATION",
  "OBSERVATION_INTEGER",
  "OBSERVATION_BOOLEAN",
  "OBSERVATION_DOUBLE",
  "OBSERVATION_DATE",
  "OBSERVATION_QUANTITY",
  "OBSERVATION_CODEABLE_CONCEPT_CODE",
];

export const PATIENT_DOB_DATA_FIELD = "PatientDOB";

export function getDataHolder(attributeType) {
  if (attributeType === "STRING") {
    return "valueString";
  } else if (attributeType === "INTEGER") {
    return "valueInteger";
  } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
    return "valueCode";
  } else if (attributeType === "BOOLEAN") {
    return "valueBoolean";
  } else if (attributeType === "DATE") {
    return "valueDate";
  } else if (attributeType === "HUMAN_NAME") {
    return "valueHumanName";
  } else if (attributeType === "ADDRESS") {
    return "valueAddress";
  } else if (attributeType === "CONTACT_POINT") {
    return "valueContactPoints";
  } else if (attributeType === "DECIMAL") {
    return "valueDouble";
  }
}

export function getObservationDataHolder(attributeType) {
  if (attributeType === "OBSERVATION_DATE") {
    return "valueDate";
  } else if (attributeType === "OBSERVATION_INTEGER") {
    return "valueInteger";
  } else if (attributeType === "OBSERVATION_QUANTITY") {
    return "valueQuantity";
  } else if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
    return "valueCode";
  } else if (attributeType === "OBSERVATION_DOUBLE") {
    return "valueDouble";
  } else {
    return "valueString";
  }
}

export function getObservationDataHolderFrom(attributeType) {
  if (attributeType === "OBSERVATION") {
    return "valueFromString";
  } else if (attributeType === "OBSERVATION_DATE") {
    return "valueFromDate";
  } else if (attributeType === "OBSERVATION_INTEGER") {
    return "valueFromInteger";
  } else if (attributeType === "OBSERVATION_QUANTITY") {
    return "valueFromQuantity";
  } else if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
    return "valueFromCode";
  } else if (attributeType === "OBSERVATION_DOUBLE") {
    return "valueFromDouble";
  }
}

export function getObservationDataHolderTo(attributeType) {
  if (attributeType === "OBSERVATION") {
    return "valueToString";
  } else if (attributeType === "OBSERVATION_DATE") {
    return "valueToDate";
  } else if (attributeType === "OBSERVATION_INTEGER") {
    return "valueToInteger";
  } else if (attributeType === "OBSERVATION_QUANTITY") {
    return "valueToQuantity";
  } else if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
    return "valueToCode";
  } else if (attributeType === "OBSERVATION_DOUBLE") {
    return "valueToDouble";
  }
}

export function needChoiceList(attributeType) {
  return attributeType && CHOICE_LIST_TYPES.indexOf(attributeType) !== -1;
}

export function isObservation(attributeType) {
  return attributeType && OBSERVATION_TYPES.indexOf(attributeType) !== -1;
}

export function isPlaceHolder(attributeType) {
  return attributeType === "PLACEHOLDER";
}

export function getValidationHints(attributeType) {
  if (attributeType === "DATE" || attributeType === "OBSERVATION_DATE") {
    return [
      { value: "PAST_DATE", label: "Past Date" },
      { value: "FUTURE_DATE", label: "Future Date" },
      { value: "DATE_RANGE", label: "Date Range" },
    ];
  } else if (
    attributeType === "INTEGER" ||
    attributeType === "OBSERVATION_INTEGER" ||
    attributeType === "OBSERVATION_DOUBLE"
  ) {
    return [{ value: "NUMBER_RANGE", label: "Number Range" }];
  }

  // if (attributeType === "OBSERVATION" || attributeType === "valueString") {
  return [
    { value: "PATTERN", label: "Regex Pattern" },
    { value: "PATTERN_WITH_SIZE", label: "Regex Pattern with Size" },
    { value: "EMAIL", label: "EMail" },
    { value: "PHONE_NUMBER", label: "Phone Number" },
    { value: "VALIDATE_WITH_MASTERDATA", label: "Validate with Master data" },
    { value: "NUMBER_RANGE", label: "Number Range" },
  ];
  // }
}

export function getValueWhenInFilter(attribute, valuePlace) {
  if (!attribute) {
    return null;
  }
  const useOnlyMonthYearForDOB = FeaturesService.useOnlyMonthYearForDOB() && attribute.name === "MPS_DOB";
  let av = attribute.value;
  let attributeType = attribute.type || attribute.attributeType;
  let value = null;

  if (!av) {
    value = null;
  } else {
    if (attributeType === "CONTACT_POINT") {
      value = ContactPoint.getDisplayValueInFilter(av);
    } else if (attributeType === "ADDRESS") {
      value = AddressAttribute.getDisplayValueInFilter(av);
    } else if (attributeType === "OBSERVATION") {
      value = StringObservation.getDisplayValue(av, valuePlace);
    } else if (attributeType === "OBSERVATION_INTEGER") {
      value = IntegerObservation.getDisplayValue(av, valuePlace);
    } else if (attributeType === "OBSERVATION_DOUBLE") {
      value = DoubleObservation.getDisplayValue(av, valuePlace);
    } else if (attributeType === "OBSERVATION_DATE") {
      value = DateObservation.getDisplayValue(av, valuePlace);
    } else if (attributeType === "HUMAN_NAME") {
      value = HumanNameAttribute.getDisplayValueInFilter(av);
    } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
      value = CodeableConceptCode.getDisplayValueInFilter(av);
    } else if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
      value = CCCObservation.getDisplayValueInFilter(av);
    } else if (attributeType === "STRING") {
      value = StringAttribute.getDisplayValue(av);
    } else if (attributeType === "INTEGER") {
      value = IntegerAttribute.getDisplayValue(av);
    } else if (attributeType === "DATE") {
      if (valuePlace === "from") {
        value = av.valueFromDate
          ? moment(av.valueFromDate).format("YYYY-MM-DD")
          : null;
      } else if (valuePlace === "to") {
        value = av.valueToDate
          ? moment(av.valueToDate).format("YYYY-MM-DD")
          : null;
      } else {
        value = av.valueDate ? moment(av.valueDate).format(useOnlyMonthYearForDOB ? "YYYY-MM" : "YYYY-MM-DD") : null;
      }
    } else if (attributeType === "POSITION") {
      return null;
    } else if (attributeType === "PLACEHOLDER") {
      value = null;
    }
  }
  if (!value) {
    return "";
  }
  return value;
}

export function getValueFieldNameForFilter(attribute, value, valuePlace) {
  let av = attribute.value || {};
  let attributeType = attribute.type || attribute.attributeType;
  let dataField = attribute.dataField;

  if (!value) {
    return null;
  }

  if (attributeType === "CONTACT_POINT") {
    if (dataField === "PatientContactPhone") {
      av = { valueContactPoints: [{ value: value, system: "PHONE" }] };
    } else if (dataField === "PatientContactEmail") {
      av = { valueContactPoints: [{ value: value, system: "EMAIL" }] };
    } else if (dataField === "GPEmail"){
      av = {valueContactPoints: [{ value: value, system: "EMAIL"}]};
    }else if (dataField === "GPPhone"){
      av = {valueContactPoints: [{ value: value, system: "PHONE"}]};
    }
  } else if (attributeType === "DATE") {
    if (valuePlace === "from") {
      if (av) {
        av.valueFromDate = value;
      } else {
        av = { valueFromDate: value };
      }
    } else if (valuePlace === "to") {
      if (av) {
        av.valueToDate = value;
      } else {
        av = { valueToDate: value };
      }
    } else {
      if (av) {
        av.valueDate = value;
      } else {
        av = { valueDate: value };
      }
    }
  } else if (attributeType === "ADDRESS") {
    av = { valueAddress: { room: value } };
  } else if (attributeType === "POSITION") {
  } else if (
    attributeType === "OBSERVATION" ||
    attributeType === "OBSERVATION_INTEGER" ||
    attributeType === "OBSERVATION_DOUBLE" ||
    attributeType === "OBSERVATION_DATE" ||
    attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE"
  ) {
    av = {
      valueObservation: { ...value },
      // valueObservation: {
      //   value: { valueString: value },
      //   code: { code: attribute.observationCode.code }
      // }
    };

    // if (valuePlace === "from") {
    //   av.valueObservation.value.valueFromString =
    //     av.valueObservation.value.valueString;
    //   delete av.valueObservation.value.valueString;
    // }
    //
    // if (valuePlace === "to") {
    //   av.valueObservation.value.valueToString =
    //     av.valueObservation.value.valueString;
    //   delete av.valueObservation.value.valueString;
    // }

    // av = {
    //   valueObservation: {
    //     value: { valueString: value },
    //     code: { code: attribute.observationCode.code }
    //   }
    // };
  } else if (attributeType === "HUMAN_NAME") {
    av = value ? { valueHumanName: { given: value } } : null;
  } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
    if (value.display) {
      av = { valueCode: { code: value.display } };
    } else {
      av = { valueCode: { code: value } };
    }
    // av = { valueCode: { code: value } };
  } else if (attributeType === "PLACEHOLDER") {
  } else if (attributeType === "STRING") {
    av = { valueString: value };
  } else if (attributeType === "INTEGER") {
    av = { valueInteger: value };
  }

  return av;
}

export function getValueForFieldsDisplay(attribute) {
  if (!attribute) {
    return null;
  }
  let av = attribute.value;
  let attributeType = attribute.attributeType || attribute.type;
  let value = null;

  if (!av) {
    value = null;
  } else {
    if (attributeType === "CONTACT_POINT") {
      value = ContactPoint.getDisplayValue(av) || null;
    } else if (attributeType === "DATE") {
      if (attribute.name === "MPS_DOB") {
        return birthDateFormatter(av.valueDate)
      }
      value = av.valueDate ? new Date(av.valueDate).toLocaleDateString() : null;
    } else if (attributeType === "ADDRESS") {
      value = AddressAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "POSITION") {
      value = PositionAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "OBSERVATION" || attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
      if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE" && !attribute.isMultipleAnswersPermitted) {
        value = CCCObservation.getDisplayValue(av) || null;
      } else {
        value =
          StringObservation.getDisplayValue(
            av,
            null,
            attribute.isMultipleAnswersPermitted
          ) || null;
      }
    } else if (attributeType === "OBSERVATION_INTEGER") {
      value = IntegerObservation.getDisplayValue(av) || null;
    } else if (attributeType === "OBSERVATION_DOUBLE") {
      value = DoubleObservation.getDisplayValue(av) || null;
    } else if (attributeType === "HUMAN_NAME") {
      value = HumanNameAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
      value = CodeableConceptCode.getDisplayValue(av) || null;
    } else if (attributeType === "PLACEHOLDER") {
      value = PlaceholderAttribute.getDisplayValue(av);
    } else if (attributeType === "STRING") {
      value = StringAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "INTEGER") {
      value = IntegerAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "OBSERVATION_DATE") {
      value = DateObservation.getDisplayValue(av) || null;
    }
  }
  return value;
}

export function generateUiAttributeAddressText({
  room,
  floor,
  line,
  city,
  district,
  state,
  postalCode,
  country,
}) {
  let textBuilder = "";
  if (room) textBuilder += room + " ";
  if (floor) textBuilder += floor + " ";
  if (line) {
    line.forEach((addLine) => {
      textBuilder += addLine + " ";
    });
  }

  if (city) textBuilder += city + " ";
  if (district) textBuilder += district + " ";
  if (state) textBuilder += state + " ";
  if (postalCode) textBuilder += postalCode + " ";
  if (country) textBuilder += country;
  return textBuilder;
}

export function generateUIAttributeNameText({ prefix, given, family, suffix }) {
  let nameText = "";
  if (prefix) {
    nameText += prefix + " ";
  }
  if (given) {
    nameText += given + " ";
  }
  if (family) {
    nameText += family + " ";
  }
  if (suffix) {
    nameText += suffix;
  }
  return nameText;
}

export function shouldShowMasterDataSelector(type) {
  return (
    type === "STRING" ||
    type === "CODEABLE_CONCEPT_CODE" ||
    type === "OBSERVATION" ||
    type === "OBSERVATION_CODEABLE_CONCEPT_CODE"
  );
}

export const ATTRIBUTES_FOR_CARE_PLAN_CRITERIA = [
  "BOOLEAN",
  "STRING",
  "INTEGER",
  "DATE",
  "CODEABLE_CONCEPT_CODE",
  "OBSERVATION",
  "OBSERVATION_INTEGER",
  "OBSERVATION_CODEABLE_CONCEPT_CODE",
];

export function getAttributeOptionsChoiceList(attributeType, options) {
  if (attributeType === "CODEABLE_CONCEPT_CODE") {
    return CodeableConceptCode.getOptionsList(options);
  }
  if (attributeType === "OBSERVATION") {
    return StringObservation.getOptionsList(options);
  }
  if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
    return CCCObservation.getOptionsList(options);
  }
}

export function getPlaceholderForAttributes(description, mandatory) {
  if (!mandatory) {
    return `${description} (Optional)`;
  }
  return description;
}

export function getAttributeByName(attributes, name) {
  let filtered = attributes.filter((a) => a.name === name);
  if (filtered) {
    return filtered[0];
  }
  return null;
}

export function validateAttribute(value, attributeValidation) {
  if (value && attributeValidation && attributeValidation.validationHint) {
    let pattern = null;
    let valueDate = null;
    switch (attributeValidation.validationHint) {
      case "PAST_DATE":
        valueDate = moment(value);
        if (!valueDate || valueDate.diff(moment(), "days") >= 0) {
          return "Enter a past date";
        }
        break;

      case "FUTURE_DATE":
        valueDate = moment(value);
        valueDate = moment(value);
        if (!valueDate || valueDate.diff(moment(), "days") < 0) {
          return "Enter a future date";
        }
        break;

      case "DATE_RANGE":
        valueDate = moment(value);
        var startDate = moment(attributeValidation.fromDate);
        var endDate = moment(attributeValidation.toDate);

        if (!valueDate || !valueDate.isBetween(startDate, endDate)) {
          return `Enter a date between ${startDate
            .toDate()
            .toLocaleDateString()} and ${endDate
            .toDate()
            .toLocaleDateString()}`;
        }
        break;
      // breaks when string obs has number range hint
      case "NUMBER_RANGE":
        if (
          isNaN(value) ||
          value < attributeValidation.fromNumber ||
          value > attributeValidation.toNumber
        ) {
          return (
            "Provide a valid number that is between " +
            attributeValidation.fromNumber +
            " and " +
            attributeValidation.toNumber
          );
        }
        break;

      case "EMAIL":
        if (!validateEmail(value)) {
          return "Provide a valid Email";
        }
        break;

      case "PHONE_NUMBER":
        if (!validatePhoneNumber(value)) {
          return "Provide a valid Phone";
        }
        break;
      // pattern = Pattern.compile(UIAttributeValidation.PhonePattern);
      // return validateContactPoint(value, pattern);
    }
  }
}

export function getPEFValues(patientProfile) {
  let sections = patientProfile.sections || [];
  let targetPEF = null;
  let predictedPEF = null;
  let targetPEFTitle = null;
  let predictedPEFTitle = null;
  sections.forEach((section) => {
    let items = section.items;
    items.forEach((item) => {
      if (item.attribute.name === "TARGET_PEF") {
        targetPEF = getValueForFieldsDisplay(item.attribute);
        targetPEFTitle = item.title;
      } else if (item.attribute.name === "PREDICTED_PEF") {
        predictedPEF = getValueForFieldsDisplay(item.attribute);
        predictedPEFTitle = item.title;
      }
    });
  });
  let data = {};
  if (targetPEF) {
    data.targetPEF = parseInt(targetPEF);
    data.targetPEFTitle = targetPEFTitle;
  }
  if (predictedPEF) {
    data.predictedPEF = parseInt(predictedPEF);
    data.predictedPEFTitle = predictedPEFTitle;
  }
  return data;
}

export function canHaveCareTeams(viewedByRole = []) {
  return (
    viewedByRole.indexOf(ROLE_GENERAL_STAFF) !== -1 ||
    viewedByRole.indexOf(ROLE_NURSE) !== -1 ||
    viewedByRole.indexOf(ROLE_DOCTOR) !== -1
  );
}
